import React from 'react';
import AlertDialog from './AlertDialog';
import type { AlertOptions } from './types';
import alertEventEmitter from './alertEventEmitter';

const AlertProvider = ({ children }) => {
  const [alerts, setAlerts]: [
    AlertOptions,
    (AlertOptions) => void,
  ] = React.useState([]);

  const onCancel = React.useCallback((index) => {
    setAlerts((old) => old.filter((a, j) => index !== j));
  }, []);

  React.useEffect(() => {
    const listener = (alert) => {
      setAlerts((old) => old.concat(alert));
    };
    alertEventEmitter.on('alert', listener);

    return () => alertEventEmitter.off('alert', listener);
  }, []);

  return (
    <>
      {alerts.map((options, index) => (
        <AlertDialog
          key={index}
          visible
          options={options}
          onCancel={() => onCancel(index)}
        />
      ))}
      {children}
    </>
  );
};

export default AlertProvider;
