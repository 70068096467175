/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React from 'reactn';
import { View } from 'react-native';
import { AppThemeProvider } from './shared/components/AppTheme';
import MaterialCommunityIconsWebImport from './lib/MaterialCommunityIconsWebImport';
import { NavigationContainer } from '@react-navigation/native';
import LoadingPage from './ui/LoadingPage';
import EntryStack from './navigators/EntryStack';
import { useUserSession } from './gstore/UserSession/hooks';
import AlertProvider from './lib/Alert/AlertProvider';
import entryLinking from './routing/entry-routing';
import UserAccessGuardContainer from './layouts/UserAccessGuardContainer';
import { DEVICE_HEIGHT, DEVICE_WIDTH } from './global/constants';
import NotificationProvider from './shared/components/Notification/NotificationProvider';
import LocaleProvider from './lang/LocaleProvider';
import './yup-custom-methods';

const App: () => React$Node = () => {
  const userSession = useUserSession();

  return (
    <View
      style={{
        width: DEVICE_WIDTH,
        height: DEVICE_HEIGHT,
      }}>
      <LocaleProvider>
        <AlertProvider>
          <NotificationProvider timeout={2000}>
            <MaterialCommunityIconsWebImport />
            <AppThemeProvider>
              {userSession.anonymous ? (
                <NavigationContainer
                  linking={entryLinking}
                  fallback={<LoadingPage />}>
                  <EntryStack />
                </NavigationContainer>
              ) : (
                <UserAccessGuardContainer />
              )}
            </AppThemeProvider>
          </NotificationProvider>
        </AlertProvider>
      </LocaleProvider>
    </View>
  );
};

export default App;
