import apiClientRequest from '../../shared/helpers/api-client-request';
import {
  SET_WORKFLOW_MODULES,
  SET_WORKFLOW_STATES,
  SET_WORKFLOWS,
} from './reducers';

export const FETCH_WORKFLOWS = 'FETCH_WORKFLOWS';

export const fetchWorkflowsSaga = async (global, dispatch) => {
  try {
    await dispatch[SET_WORKFLOWS]({
      loading: true,
    });
    const workflows = await apiClientRequest('/workflows/all');
    await dispatch[SET_WORKFLOWS]({
      data: workflows,
      loading: false,
    });
  } catch (err) {
    console.error(err);
  }
};

export const FETCH_WORKFLOW_MODULES = 'FETCH_WORKFLOW_MODULES';

export const fetchWorkflowModulesSaga = async (global, dispatch) => {
  try {
    dispatch[SET_WORKFLOW_MODULES]({
      loading: true,
    });
    const modules = await apiClientRequest('/workflow-modules/all');
    dispatch[SET_WORKFLOW_MODULES]({
      data: modules,
      loading: false,
    });
  } catch (err) {
    console.error(err);
  }
};

export const FETCH_WORKFLOW_STATES = 'FETCH_WORKFLOW_STATES';

export const fetchWorkflowStatesSaga = async (global, dispatch) => {
  try {
    dispatch[SET_WORKFLOW_STATES]({
      loading: true,
    });
    const states = await apiClientRequest('/workflow-states/all');
    dispatch[SET_WORKFLOW_STATES]({
      data: states,
      loading: false,
    });
  } catch (err) {
    console.error(err);
  }
};
