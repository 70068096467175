import React from 'react';
import { Image, StyleSheet, Dimensions, View } from 'react-native';
import images from '../global/images';
import makeStyles from '../shared/helpers/make-styles';

const LoadingPage = () => {
  const styles = useStyles();
  return (
    <View style={styles.wrapper}>
      <Image style={styles.logo} source={{ uri: images.logoFullColored }} />
    </View>
  );
};

const useStyles = makeStyles((theme) =>
  StyleSheet.create({
    wrapper: {
      backgroundColor: theme.palette.primary.contrastColor,
      height: Dimensions.get('window').height,
      justifyContent: 'center',
      alignItems: 'center',
    },
    logo: {
      width: 156,
      height: 35,
    },
  }),
);

export default LoadingPage;
