import React from 'react';
import { Platform } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import MaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';

const MaterialCommunityIconsWebImport = () => {
  return Platform.OS === 'web' ? (
    <style type="text/css">{`
        input, textarea {
          outline: none !important;
        }
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${MaterialCommunityIcons}) format('truetype');
        }
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${MaterialIcons}) format('truetype');
        }
      `}</style>
  ) : null;
};

export default MaterialCommunityIconsWebImport;
