import React from 'reactn';
import {
  ADD_CUSTOM_FIELD,
  ADD_CUSTOM_FIELD_TO_WORKFLOW,
  addCustomFieldReducer,
  addCustomFieldToWorkflowReducer,
  SET_CUSTOM_FIELDS,
  setCustomFieldsReducer,
} from './reducers';

export const customFieldsInitGState = () => {
  React.setGlobal({
    customFields: {
      data: [],
      loading: false,
      error: false,
      init: false,
    },
  });
  React.addReducer(SET_CUSTOM_FIELDS, setCustomFieldsReducer);
  React.addReducer(
    ADD_CUSTOM_FIELD_TO_WORKFLOW,
    addCustomFieldToWorkflowReducer,
  );
  React.addReducer(ADD_CUSTOM_FIELD, addCustomFieldReducer);
};
