import React from 'reactn';

export const useUserSession = () => React.useGlobal('userSession')[0];

export const useUserSessionSpaceDataReady = () =>
  React.useGlobal('spaceDataReady')[0];

export const useCurrentUser = () => React.useGlobal('currentUser')[0];

export const useUserSubscription = () =>
  React.useGlobal('currentUserSubscription')[0];

export const useUserQuotaUsage = () =>
  React.useGlobal('currentUserQuotaUsage')[0];

export const useUserSubscriptionPlan = () =>
  React.useGlobal('currentUserSubscriptionPlan')[0];

export const useUserDataReady = () => React.useGlobal('userDataReady')[0];

export const useCurrentUserSubscriber = () =>
  React.useGlobal('currentUserSubscriber')[0];

export const usePaymentMethod = () =>
  React.useGlobal('subscriberPaymentMethod')[0];

export const usePaymentProcessing = () =>
  React.useGlobal('paymentProcessing')[0];

export const useUsersNumber = () => React.useGlobal('usersNumber')[0];

export const useSpaceSubscription = () =>
  React.useGlobal('spaceSubscription')[0];
