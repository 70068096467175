import React from 'reactn';
import {
  FETCH_WORKFLOW_MODULES,
  FETCH_WORKFLOW_STATES,
  FETCH_WORKFLOWS,
  fetchWorkflowModulesSaga,
  fetchWorkflowsSaga,
  fetchWorkflowStatesSaga,
} from './sagas';
import {
  SET_WORKFLOW_MODULES,
  SET_WORKFLOW_STATES,
  SET_WORKFLOWS,
  setWorkflowModulesReducer,
  setWorkflowsReducer,
  setWorkflowStatesReducer,
} from './reducers';

const initWorkflowsGState = () => {
  React.setGlobal({
    workflows: {
      data: [],
      loading: false,
      error: false,
      init: false,
    },
    modules: {
      data: [],
      loading: false,
      error: false,
      init: false,
    },
    states: {
      data: [],
      loading: false,
      error: false,
      init: false,
    },
  });

  React.addReducer(SET_WORKFLOWS, setWorkflowsReducer);
  React.addReducer(SET_WORKFLOW_MODULES, setWorkflowModulesReducer);
  React.addReducer(SET_WORKFLOW_STATES, setWorkflowStatesReducer);
  React.addReducer(FETCH_WORKFLOW_MODULES, fetchWorkflowModulesSaga);
  React.addReducer(FETCH_WORKFLOWS, fetchWorkflowsSaga);
  React.addReducer(FETCH_WORKFLOW_STATES, fetchWorkflowStatesSaga);
};

export default initWorkflowsGState;
