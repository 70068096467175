import React from 'react';

const theme = {
  palette: {
    primary: {
      main: '#0078D4',
      dark: '#0165b0',
      contrastColor: '#fff',
    },
    secondary: {
      light: '#FAFAFA',
      main: '#333',
      dark: '#000',
      contrastColor: '#fff',
    },
    error: {
      main: '#eb5a46',
      contrastColor: '#fff',
    },
    warning: {
      light: '#f2d600',
      main: '#ff9f1a',
      contrastColor: '#fff',
    },
    info: {
      main: '#2196f3',
      contrastColor: '#fff',
    },
    success: {
      main: '#08A742',
      contrastColor: '#fff',
    },
  },
  typography: {
    fonts: {
      light: 'Roboto Thin',
      regular: 'Roboto Regular',
      medium: 'Roboto medium',
      xbold: 'Roboto Black',
      bold: 'Roboto Bold',
    },
  },
  spacing: (value) => value * 8,
  global: {
    page: {
      backgroundColor: '#fefefe',
    },
    page2: {
      backgroundColor: '#F2F2F2',
    },
    forms: {
      readOnlyBackground: '#f0f0f0',
    },
  },
};

type AppThemePaletteColorType = {
  main: string,
  dark: string,
  contrastColor: string,
  light: string,
};

export type AppThemeType = {
  palette: {
    primary: AppThemePaletteColorType,
    secondary: AppThemePaletteColorType,
    error: AppThemePaletteColorType,
    warning: AppThemePaletteColorType,
    info: AppThemePaletteColorType,
    success: AppThemePaletteColorType,
  },
  typography: {
    fonts: {
      light: string,
      regular: string,
      medium: string,
      xbold: string,
      bold: string,
    },
  },
  global: {
    page: any,
    forms: any,
  },
  spacing: Function,
};

const AppTheme: AppThemeType = React.createContext(theme);

export const AppThemeProvider = (props) => (
  <AppTheme.Provider value={theme} {...props} />
);

export default AppTheme;
