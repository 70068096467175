import React from 'react';
import { Text, StyleSheet } from 'react-native';

export const Paragraph = (props) => (
  <Text style={styles.paragraph} {...props} />
);

export const Bold = (props) => <Text style={styles.bold} {...props} />;

const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  paragraph: {
    marginBottom: 10,
  },
});
