import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import PropTypes from 'prop-types';
import makeStyles from '../../helpers/make-styles';

const icons = {
  error: 'error',
  success: 'done',
  warning: 'warning',
};

const NotificationMessage = ({ style, type, message }) => {
  const styles = useStyles();
  return (
    <View style={[styles.wrapper, styles[type], style]}>
      <Icon style={styles.icon} name={icons[type]} size={18} color="#fff" />
      <Text style={styles.text}>{message}</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) =>
  StyleSheet.create({
    wrapper: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 7,
      paddingRight: 7,
      flexDirection: 'row',
      alignItems: 'center',
    },
    error: {
      backgroundColor: '#eb5a46',
    },
    warning: {
      backgroundColor: '#f2d600',
    },
    success: {
      backgroundColor: '#08A742',
    },
    text: {
      color: '#fff',
      fontFamily: theme.typography.fonts.medium,
    },
    icon: {
      marginRight: 4,
    },
  }),
);

NotificationMessage.propTypes = {
  type: PropTypes.oneOf(['warning', 'error', 'success']),
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default NotificationMessage;
