import logoColored from '../assets/images/logo-colored.png';
import logoFullColored from '../assets/images/logo-full-colored.png';
import googleIcon from '../assets/images/google-icon.png';
import facebookIcon from '../assets/images/facebook-icon-round.png';
import linkedinIcon from '../assets/images/linkedin-icon.png';
import paymentMethodsOptions from '../assets/images/payment-method-logo.png';
import unionPay from '../assets/images/payment-methods/union-pay.png';
import visa from '../assets/images/payment-methods/visa.png';
import jcb from '../assets/images/payment-methods/jcb.png';
import masterCard from '../assets/images/payment-methods/master-card.png';
import discover from '../assets/images/payment-methods/discover.png';
import dinersClub from '../assets/images/payment-methods/diners-club.png';
import americanExpress from '../assets/images/payment-methods/american-express.png';

export default {
  logoColored,
  googleIcon,
  facebookIcon,
  logoFullColored,
  linkedinIcon,
  paymentMethodsOptions,
  unionPay,
  visa,
  jcb,
  masterCard,
  discover,
  dinersClub,
  americanExpress,
};
