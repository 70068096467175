import React from 'reactn';
import { SET_WORKSPACES, setWorkspacesReducer } from './reducers';

const initSpacesGState = () => {
  React.setGlobal({
    workspaces: [],
  });

  React.addReducer(SET_WORKSPACES, setWorkspacesReducer);
};

export default initSpacesGState;
