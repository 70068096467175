import React from 'reactn';
import {
  REMOVE_PAYMENT_METHOD,
  removePaymentMethodReducer,
  SET_CURRENT_USER,
  SET_CURRENT_USER_ACTIVE_SUBSCRIPTION,
  SET_CURRENT_USER_QUOTA_USAGE,
  SET_CURRENT_USER_SUBSCRIBER,
  SET_CURRENT_USER_SUBSCRIPTION_PLAN,
  SET_PAYMENT_PROCESSING,
  SET_SPACE_SUBSCRIPTION,
  SET_SUBSCRIBER_PAYMENT_METHOD,
  SET_USER_SESSION,
  SET_USER_SESSION_AUTH_DATA_READY,
  SET_USER_SESSION_SPACE_DATA_READY,
  SET_USERS_NUMBER,
  setCurrentUserActiveSubscriptionReducer,
  setCurrentUserQuotaUsageReducer,
  setCurrentUserReducer,
  setCurrentUserSubscriberReducer,
  setCurrentUserSubscriptionPlanReducer,
  setPaymentProcessingReducer,
  setSpaceSubscriptionReducer,
  setSubscriberPaymentMethodReducer,
  setUserSessionAuthDataReadySaga,
  setUserSessionReducer,
  setUserSessionSpaceDataReadySaga,
  setUsersNumberReducer,
} from './reducers';
import {
  ATTEMPT_RELOAD_SESSIONS_AFTER_SUCCESSFUL_CHECKOUT,
  ATTEMPT_TO_UPDATE_SUBSCRIPTION_IF_PAYMENT_IS_PROCESSING,
  attemptReloadSessionsAfterSuccessfulCheckoutReducer,
  attemptToUpdateSubscriptionIfPaymentIsProcessing,
  FETCH_USER_SESSION_AUTH_DATA,
  GET_USER_SESSION_SPACE_DATA,
  fetchUserSessionAuthDataSaga,
  getUserSessionSpaceDataSaga,
  LOGOUT,
  logoutSaga,
  SAVE_USER_SESSION,
  saveUserSessionSaga,
  UPDATE_CHECKOUT_PROCESSING,
  updateCheckoutProcessingSaga,
  updateSpaceSessionSaga,
  UPDATE_SPACE_SESSION,
} from './sagas';
import UserSession from '../../shared/models/UserSession';

const UserSessionGStateInit = async () => {
  const userSession = await UserSession.getUserSession();
  React.setGlobal({
    userSession,
    spaceDataReady: false,
    userDataReady: false,
    currentUser: null,
    subscriberPaymentMethod: null,
  });
  React.addReducer(SET_USER_SESSION, setUserSessionReducer);
  React.addReducer(SAVE_USER_SESSION, saveUserSessionSaga);
  React.addReducer(FETCH_USER_SESSION_AUTH_DATA, fetchUserSessionAuthDataSaga);
  React.addReducer(GET_USER_SESSION_SPACE_DATA, getUserSessionSpaceDataSaga);
  React.addReducer(
    SET_USER_SESSION_SPACE_DATA_READY,
    setUserSessionSpaceDataReadySaga,
  );
  React.addReducer(
    SET_CURRENT_USER_SUBSCRIPTION_PLAN,
    setCurrentUserSubscriptionPlanReducer,
  );
  React.addReducer(
    SET_CURRENT_USER_QUOTA_USAGE,
    setCurrentUserQuotaUsageReducer,
  );
  React.addReducer(
    SET_CURRENT_USER_SUBSCRIBER,
    setCurrentUserSubscriberReducer,
  );
  React.addReducer(
    SET_USER_SESSION_AUTH_DATA_READY,
    setUserSessionAuthDataReadySaga,
  );
  React.addReducer(SET_CURRENT_USER, setCurrentUserReducer);
  React.addReducer(
    SET_CURRENT_USER_ACTIVE_SUBSCRIPTION,
    setCurrentUserActiveSubscriptionReducer,
  );
  React.addReducer(
    SET_SUBSCRIBER_PAYMENT_METHOD,
    setSubscriberPaymentMethodReducer,
  );
  React.addReducer(LOGOUT, logoutSaga);
  React.addReducer(
    ATTEMPT_TO_UPDATE_SUBSCRIPTION_IF_PAYMENT_IS_PROCESSING,
    attemptToUpdateSubscriptionIfPaymentIsProcessing,
  );
  React.addReducer(
    ATTEMPT_RELOAD_SESSIONS_AFTER_SUCCESSFUL_CHECKOUT,
    attemptReloadSessionsAfterSuccessfulCheckoutReducer,
  );
  React.addReducer(UPDATE_CHECKOUT_PROCESSING, updateCheckoutProcessingSaga);
  React.addReducer(SET_PAYMENT_PROCESSING, setPaymentProcessingReducer);
  React.addReducer(SET_USERS_NUMBER, setUsersNumberReducer);
  React.addReducer(REMOVE_PAYMENT_METHOD, removePaymentMethodReducer);
  React.addReducer(UPDATE_SPACE_SESSION, updateSpaceSessionSaga);
  React.addReducer(SET_SPACE_SUBSCRIPTION, setSpaceSubscriptionReducer);
};

export default UserSessionGStateInit;
