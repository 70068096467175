import { Dimensions } from 'react-native';

export const TERMS_AND_CONDITIONS_URL =
  'https://www.prediwell.com/term-and-conditions/';

export const PRIVACY_AND_POLICY_URL = 'https://www.prediwell.com/privacy/';

export const DEVICE_WIDTH = Dimensions.get('window').width;
export const DEVICE_HEIGHT = Dimensions.get('window').height;

export const SUBSCRIPTION_PLAN_PRODUCT = {
  PLANNING_BOARD: 'PLANNING_BOARD',
  TASK_MANAGEMENT: 'TASK_MANAGEMENT',
  DASHBOARD: 'DASHBOARD',
  MAILING: 'MAILING',
  FILES_STORAGE: 'FILES_STORAGE',
  DATA_EXPORT: 'DATA_EXPORT',
  DATA_IMPORT: 'DATA_IMPORT',
};
