import React from 'reactn';
import { SET_USER_APP_TOUR, setUserAppTourReducer } from './reducers';
import UserSession from '../../shared/models/UserSession';
import {
  FETCH_USER_APP_TOUR,
  fetchUserAppTourSaga,
  MARK_APP_PROGRESS_STEP_AS_DONE,
  markAppProgressStepAsDoneSaga,
} from './sagas';
import { apiGetAppProgress } from './api';

const initUserAppTour = async () => {
  const userLoggedIn = await UserSession.isUserLoggedIn();
  let userAppTour;
  if (userLoggedIn) {
    userAppTour = await apiGetAppProgress();
  } else {
    userAppTour = null;
  }
  React.setGlobal({
    userAppTour: {
      data: userAppTour,
      loading: false,
      error: false,
    },
  });

  React.addReducer(SET_USER_APP_TOUR, setUserAppTourReducer);
  React.addReducer(FETCH_USER_APP_TOUR, fetchUserAppTourSaga);
  React.addReducer(
    MARK_APP_PROGRESS_STEP_AS_DONE,
    markAppProgressStepAsDoneSaga,
  );
};

export default initUserAppTour;
