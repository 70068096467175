import React from 'reactn';
import {
  HIDE_ACTIVITIES_LIST_TAB_BAR,
  hideActivitiesListTabBarReducer,
  SHOW_ACTIVITIES_LIST_TAB_BAR,
  showActivitiesListTabBarReducer,
} from './reducers';

const UIGStateInit = () => {
  React.setGlobal({
    activitiesListTabBarVisible: true,
  });

  React.addReducer(
    SHOW_ACTIVITIES_LIST_TAB_BAR,
    showActivitiesListTabBarReducer,
  );
  React.addReducer(
    HIDE_ACTIVITIES_LIST_TAB_BAR,
    hideActivitiesListTabBarReducer,
  );
};

export default UIGStateInit;
