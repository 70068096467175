import React from 'reactn';
import { CHANGE_LOCALE, changeLocaleSaga } from './sagas';
import { SET_LOCALE, setLocaleReducer } from './reducers';
import storage from '../../lib/Storage/storage';

const initLocaleGState = async () => {
  React.addReducer(CHANGE_LOCALE, changeLocaleSaga);
  React.addReducer(SET_LOCALE, setLocaleReducer);
  const locale = await storage.getItem('APP_LOCALE');
  await React.setGlobal({
    locale: locale || 'en',
  });
};

export default initLocaleGState;
