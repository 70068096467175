import notificationEventEmitter from './notificationEventEmitter';

const NotificationManager = {
  success: (message) =>
    notificationEventEmitter.emit('notification', { type: 'success', message }),
  error: (message) =>
    notificationEventEmitter.emit('notification', { type: 'error', message }),
  warning: (message) =>
    notificationEventEmitter.emit('notification', { type: 'warning', message }),
};

export default NotificationManager;
