import React from 'react';
import { useIntl } from 'react-intl';
import { Bold, Paragraph } from './Formatters';

// Injected message
const IntlMessages = (props) => {
  const intl = useIntl();

  return intl.formatMessage(
    {
      id: props.id,
      defaultMessage: props.defaultMessage,
    },
    {
      p: (chunks) => <Paragraph>{chunks}</Paragraph>,
      b: (chunks) => <Bold>{chunks}</Bold>,
      ...props.values,
    },
  );
};

export const useIntlMessage = (args = '') => {
  const intl = useIntl();

  let id;
  let defaultMessage;
  let values;

  if (typeof args === 'string') {
    id = args;
  } else {
    id = args.id;
    defaultMessage = args.defaultMessage;
    values = args.values;
  }

  return intl.formatMessage(
    {
      id,
      defaultMessage: defaultMessage,
    },
    {
      p: (chunks) => <Paragraph>{chunks}</Paragraph>,
      b: (chunks) => <Bold>{chunks}</Bold>,
      ...values,
    },
  );
};

export default IntlMessages;
