import en from './entries/en-US';
import fr from './entries/fr_FR';
import es from './entries/es_ES';

const AppLocale = {
  en,
  fr,
  es,
};

export default AppLocale;
