import React from 'react';
import useTheme from '../hooks/use-theme';

const makeStyles = (styleFactory) => () => {
  const theme = useTheme();

  return React.useMemo(() => {
    return styleFactory(theme);
  }, [theme]);
};

export default makeStyles;
