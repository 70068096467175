const entryConfig = {
  initialRouteName: 'Signup',
  screens: {
    Signup: 'signup',
    Signin: 'signin',
    AccountVerification: '_verify-account/:token',
    ForgotPassword: 'reset-password',
  },
};

const entryLinking = {
  prefixes: [],
  config: entryConfig,
};

export default entryLinking;
