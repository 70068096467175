import * as yup from 'yup';

function conditionalRequired(conditionTest, message) {
  return this.test('conditionalRequired', function (value) {
    const { path, createError, parent } = this;
    return conditionTest(parent) || value
      ? true
      : createError({
          path,
          message,
        });
  });
}

yup.addMethod(yup.mixed, 'conditionalRequired', conditionalRequired);
