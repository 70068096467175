import { INTERNAL_ERROR } from '../Logs/sagas';
import { SET_USER_APP_TOUR } from './reducers';
import { apiGetAppProgress, apiUpdateAppProgressStep } from './api';

export const FETCH_USER_APP_TOUR = 'FETCH_USER_APP_TOUR';

export const fetchUserAppTourSaga = async (global, dispatch) => {
  try {
    dispatch[SET_USER_APP_TOUR]({
      loading: true,
    });
    const userAppTour = await apiGetAppProgress();
    dispatch[SET_USER_APP_TOUR]({
      data: userAppTour,
      loading: false,
    });
  } catch (err) {
    dispatch[INTERNAL_ERROR](err);
  }
};

export const MARK_APP_PROGRESS_STEP_AS_DONE =
  'MARK_WIZARD_PROGRESS_STEP_AS_DONE';

export const markAppProgressStepAsDoneSaga = async (global, dispatch, step) => {
  try {
    if (
      global.wizardProgress.steps &&
      !global.wizardProgress.steps.includes(step)
    ) {
      await apiUpdateAppProgressStep(step);

      return {
        wizardProgress: {
          ...global.wizardProgress,
          steps: global.wizardProgress.steps.concat(step),
        },
      };
    }
  } catch (err) {
    dispatch[INTERNAL_ERROR](err);
  }
};
