export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS';

export const setCustomFieldsReducer = (global, dispatch, data) => ({
  customFields: {
    ...data,
  },
});

export const ADD_CUSTOM_FIELD = 'ADD_CUSTOM_FIELD';

export const addCustomFieldReducer = (global, dispatch, customField) => ({
  customFields: {
    ...global.customFields,
    data: [...global.customFields.data, customField],
  },
});

export const ADD_CUSTOM_FIELD_TO_WORKFLOW = 'ADD_CUSTOM_FIELD_TO_WORKFLOW';

export const addCustomFieldToWorkflowReducer = (
  global,
  dispatch,
  { customFieldId, workflow },
) => ({
  customFields: {
    ...global.customFields,
    data: global.customFields.data.map((customField) => {
      if (customField._id === customFieldId) {
        const workflows = customField.workflow || [];
        return {
          ...customField,
          workflow: workflows.concat(workflow),
        };
      }

      return customField;
    }),
  },
});

export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';

export const deleteCustomFieldReducer = (global, dispatch, id) => ({
  customFields: global.customFields.filter(
    (customField) => customField._id !== id,
  ),
});
