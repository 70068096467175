import React from 'reactn';
import { CHANGE_LOCALE } from '../gstore/Locale/sagas';

const getLang = (lang) => {
  if (['en', 'fr', 'es'].includes(lang)) {
    return lang;
  }

  return null;
};

const useLocale = () => {
  const changeLocale = React.useDispatch(CHANGE_LOCALE);
  const params = new URLSearchParams(window.location.search);
  const locale = React.useGlobal('locale')[0];
  const paramLang = params.get('lang');

  React.useEffect(() => {
    if (paramLang) {
      changeLocale(paramLang);
    }
  }, [changeLocale, paramLang]);
  return getLang(paramLang) || locale;
};

export default useLocale;
