export const SET_USER_SESSION = 'SET_USER_SESSION';

export const setUserSessionReducer = (global, dispatch, userSession) => ({
  userSession,
});

export const SET_USER_SESSION_SPACE_DATA_READY =
  'SET_USER_SESSION_SPACE_DATA_READY';

export const setUserSessionSpaceDataReadySaga = (global, dispatch, ready) => ({
  spaceDataReady: ready,
});

export const SET_USER_SESSION_AUTH_DATA_READY =
  'SET_USER_SESSION_USER_DATA_READY';

export const setUserSessionAuthDataReadySaga = (global, dispatch, ready) => ({
  userDataReady: ready,
});

export const SET_CURRENT_USER_SUBSCRIPTION_PLAN =
  'SET_CURRENT_USER_SUBSCRIPTION_PLAN';

export const setCurrentUserSubscriptionPlanReducer = (
  global,
  dispatch,
  { currentUserSubscriptionPlan },
) => ({
  currentUserSubscriptionPlan,
});

export const SET_CURRENT_USER_QUOTA_USAGE = 'SET_CURRENT_USER_QUOTA_USAGE';

export const setCurrentUserQuotaUsageReducer = (
  global,
  dispatch,
  { currentUserQuotaUsage },
) => ({
  currentUserQuotaUsage,
});

export const SET_CURRENT_USER_SUBSCRIBER = 'SET_CURRENT_USER_SUBSCRIBER';

export const setCurrentUserSubscriberReducer = (
  global,
  dispatch,
  { currentUserSubscriber },
) => ({
  currentUserSubscriber,
});

export const SET_CURRENT_USER_ACTIVE_SUBSCRIPTION =
  'SET_CURRENT_USER_ACTIVE_SUBSCRIPTION';

export const setCurrentUserActiveSubscriptionReducer = (
  global,
  dispatch,
  { currentUserSubscription },
) => ({
  currentUserSubscription,
});

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const setCurrentUserReducer = (global, dispatch, currentUser) => {
  return {
    currentUser,
  };
};

export const SET_SUBSCRIBER_PAYMENT_METHOD = 'SET_SUBSCRIBER_PAYMENT_METHOD';

export const setSubscriberPaymentMethodReducer = (
  global,
  dispatch,
  { paymentMethod },
) => ({
  subscriberPaymentMethod: paymentMethod,
});

export const SET_PAYMENT_PROCESSING = 'SET_PAYMENT_PROCESSING';

export const setPaymentProcessingReducer = (
  global,
  dispatch,
  paymentProcessing,
) => ({
  paymentProcessing,
});

export const SET_USERS_NUMBER = 'SET_USERS_NUMBER';

export const setUsersNumberReducer = (global, dispatch, usersNumber) => ({
  usersNumber,
});

export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';

export const removePaymentMethodReducer = () => ({
  subscriberPaymentMethod: null,
});

export const SET_SPACE_SUBSCRIPTION = 'SET_SPACE_SUBSCRIPTION';

export const setSpaceSubscriptionReducer = (
  global,
  dispatch,
  spaceSubscription,
) => ({
  spaceSubscription,
});
