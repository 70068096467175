import React from 'react';
import uniqueId from 'lodash/uniqueId';
import {
  Modal,
  View,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import NotificationContext from './NotificationContext';
import NotificationMessage from './NotificationMessage';
import notificationEventEmitter from './notificationEventEmitter';

const NotificationProvider = ({ timeout: propTimeout = 1000, children }) => {
  const [notifications, setNotifications] = React.useState([]);

  const showNotification = React.useCallback(
    ({ type, message, timeout = 1000 }) => {
      const newNotification = {
        id: uniqueId(),
        type,
        message,
      };

      setNotifications((oldValue: Array) => oldValue.concat(newNotification));

      setTimeout(() => {
        setNotifications((oldValue: Array) =>
          oldValue.filter((c) => c !== newNotification),
        );
      }, timeout);
    },
    [],
  );

  React.useEffect(() => {
    notificationEventEmitter.on('notification', (notification) => {
      showNotification({
        type: notification.type,
        message: notification.message,
        timeout: propTimeout,
      });
    });
  }, [propTimeout, showNotification]);
  return (
    <NotificationContext.Provider value={{ notifications, showNotification }}>
      <Modal
        onDismiss={() => setNotifications([])}
        visible={notifications.length > 0}
        transparent={true}
        animationType="fade">
        <TouchableWithoutFeedback onPress={() => setNotifications([])}>
          <View style={styles.overlay}>
            {notifications.map((notification) => (
              <NotificationMessage
                key={notification.id}
                style={styles.message}
                type={notification.type}
                message={notification.message}
              />
            ))}
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      {children}
    </NotificationContext.Provider>
  );
};

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: '#0001',
    position: 'relative',
    flexDirection: 'column-reverse',
    flex: 1,
    alignItems: 'center',
    paddingBottom: 20,
    paddingTop: 20,
  },
  message: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default NotificationProvider;
