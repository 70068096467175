import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, StyleSheet, Text } from 'react-native';
import type { AppThemeType } from '../../shared/components/AppTheme';
import useTheme from '../../shared/hooks/use-theme';
import { rem } from '../Typography/utils';

const variants = {
  default: (theme: AppThemeType) => ({
    color: theme.palette.secondary.main,
    textColor: theme.palette.secondary.contrastColor,
    borderColor: 'transparent',
    fontFamily: theme.typography.fonts.xbold,
    textTransform: 'uppercase',
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
  }),
  cta: (theme) => ({
    color: '#08A742',
    textColor: theme.palette.primary.contrastColor,
    borderColor: 'transparent',
    fontFamily: theme.typography.fonts.xbold,
    textTransform: 'uppercase',
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
  }),
  outlined: (theme) => ({
    textColor: '#4F4F4F',
    color: 'inherit',
    borderColor: 'rgba(0, 0, 0, 0.14)',
    fontFamily: theme.typography.fonts.medium,
    textTransform: 'uppercase',
    boxShadow: 'none',
  }),
  transparent: (theme: AppThemeType) => ({
    color: 'inherit',
    textColor: theme.palette.secondary.dark,
    textTransform: 'uppercase',
    fontFamily: theme.typography.fonts.medium,
    boxShadow: 'none',
  }),
};

const sizes = {
  large: {
    fontSize: rem(0.875),
    letterSpacing: 1.6,
    height: 45,
  },
  small: {
    fontSize: rem(0.75),
    letterSpacing: 1.4,
    height: 30,
  },
};

const shapes = {
  default: {
    borderRadius: 4,
  },
  round: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
};

const ButtonVariantContext = React.createContext({});
const ButtonSizeContext = React.createContext({});
const ButtonShapeContext = React.createContext({});

const Button = React.forwardRef(({ variant, shape, size, ...props }, ref) => {
  const theme = useTheme();

  return (
    <ButtonShapeContext.Provider value={shapes[shape || 'default']}>
      <ButtonSizeContext.Provider value={sizes[size || 'large']}>
        <ButtonVariantContext.Provider
          value={variants[variant || 'default'](theme)}>
          <VariantButton ref={ref} {...props} />
        </ButtonVariantContext.Provider>
      </ButtonSizeContext.Provider>
    </ButtonShapeContext.Provider>
  );
});

const VariantButton = ({
  style,
  textStyle,
  variant,
  children,
  textProps,
  ...props
}) => {
  const styles = useStyles();

  return (
    <TouchableOpacity
      activeOpacity={0.75}
      style={[styles.button, style]}
      {...props}>
      <Text style={[styles.text, textStyle]} {...textProps}>
        {children}
      </Text>
    </TouchableOpacity>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['cta', 'outlined', 'default', 'transparent']),
  size: PropTypes.oneOf(['small', 'large']),
  shape: PropTypes.oneOf(['round', 'default']),
};

const useStyles = () => {
  const variant = React.useContext(ButtonVariantContext);
  const size = React.useContext(ButtonSizeContext);
  const shape = React.useContext(ButtonShapeContext);

  return React.useMemo(
    () =>
      StyleSheet.create({
        button: {
          justifyContent: 'center',
          paddingLeft: 16,
          paddingRight: 16,
          width: shape.width || size.width,
          height: shape.height || size.height,
          backgroundColor: variant.color,
          borderRadius: shape.borderRadius,
          boxShadow: variant.boxShadow,
          border: `1px solid ${variant.borderColor}`,
        },
        text: {
          fontFamily: variant.fontFamily,
          fontSize: variant.fontSize || size.fontSize,
          letterSpacing: size.letterSpacing,
          textAlign: 'center',
          color: variant.textColor,
          textTransform: variant.textTransform,
        },
      }),
    [
      shape.width,
      shape.height,
      shape.borderRadius,
      size.width,
      size.height,
      size.fontSize,
      size.letterSpacing,
      variant.color,
      variant.boxShadow,
      variant.borderColor,
      variant.fontFamily,
      variant.fontSize,
      variant.textColor,
      variant.textTransform,
    ],
  );
};

export default Button;
