import UserSession from '../models/UserSession';

const validateAuthentication = async () => {
  const session = await UserSession.getUserSession();
  if (session.exp * 1000 < new Date().getTime()) {
    await UserSession.clear();
  }
};

export default validateAuthentication;
