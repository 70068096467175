import UserSessionGStateInit from './UserSession/init';
import initWorkflowsGState from './Workflows/init';
import { customFieldsInitGState } from './CustomFields/init';
import initLogsGState from './Logs/init';
import initUsersGState from './Users/init';
import initUserAppTour from './AppTour/init';
import UIGStateInit from './UI/init';
import initLocaleGState from './Locale/init';
import initSpacesGState from './Spaces/init';

const gInit = async () => {
  await UserSessionGStateInit();
  await initWorkflowsGState();
  await customFieldsInitGState();
  await initLogsGState();
  await initUsersGState();
  await initUserAppTour();
  await UIGStateInit();
  await initLocaleGState();
  await initSpacesGState();
};

export default gInit;
