import React from 'reactn';
import AppLocale from './index';
import { IntlProvider } from 'react-intl';
import useLocale from './useLocale';

const LocaleProvider = ({ children }) => {
  const locale = useLocale();

  const currentAppLocale = AppLocale[locale];

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      {children}
    </IntlProvider>
  );
};

export default LocaleProvider;
