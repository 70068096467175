import React from 'react';
import NotificationManager from '../../shared/components/Notification/NotificationManager';
import IntlMessages from '../../shared/intl/IntlMessages';

export const INTERNAL_ERROR = 'INTERNAL_ERROR';

export const internalErrorSaga = (global, dispatch, error) => {
  NotificationManager.error(<IntlMessages id="global.internalError" />);
  console.error(error);
};
