/**
 * @format
 */

import { AppRegistry } from 'react-native';
import App from './App';
import { name as appName } from './app.json';
import gInit from './gstore/init';
import validateAuthentication from './shared/helpers/validate-authentication';

async function bootstrap() {
  AppRegistry.registerComponent(appName, () => App);
  await validateAuthentication();
  await gInit();
  AppRegistry.runApplication(appName, {
    rootTag: document.getElementById('root'),
  });
}

bootstrap();
