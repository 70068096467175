import React from 'reactn';
import { SET_USERS, setUsersReducer } from './reducers';

const initUsersGState = () => {
  React.setGlobal({
    users: {
      data: [],
      loading: false,
      error: false,
    },
  });
  React.addReducer(SET_USERS, setUsersReducer);
};

export default initUsersGState;
