import apiClientRequest from '../../shared/helpers/api-client-request';

export const apiUpdateAppProgressStep = (step) =>
  apiClientRequest('/app-progress/steps', {
    data: {
      step,
    },
    method: 'PATCH',
  });

export const apiGetAppProgress = () => apiClientRequest('/app-progress');
