import React from 'react';
import delay from 'lodash/delay';
import PropTypes from 'prop-types';
import {
  Modal,
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import makeStyles from '../../shared/helpers/make-styles';
import Button from '../../ui/Button/Button';
import type { AppThemeType } from '../../shared/components/AppTheme';
import { rem } from '../../ui/Typography/utils';

const AlertDialog = ({ visible, options, onCancel }) => {
  const styles = useStyles();
  const [isVisible, setVisible] = React.useState(visible);
  const hide = React.useCallback(() => {
    setVisible(false);
  }, []);

  const onCancelClick = React.useCallback(() => {
    hide();
    delay(onCancel, 500);
  }, [onCancel, hide]);

  const blockModalEventPropagation = React.useCallback((evt) => {
    evt.stopPropagation();
  }, []);

  return (
    <Modal
      animationType="fade"
      onDismiss={onCancel}
      visible={isVisible}
      transparent={true}>
      <TouchableWithoutFeedback onPress={onCancelClick}>
        <View style={styles.overlay}>
          <TouchableWithoutFeedback onPress={blockModalEventPropagation}>
            <View style={styles.modal}>
              <Text style={styles.heading}>{options.title}</Text>
              <Text style={styles.message}>{options.message}</Text>
              <View style={styles.actions}>
                {options.buttons?.map((button, index) => (
                  <Button
                    key={index}
                    variant="transparent"
                    onPress={
                      button.style === 'cancel'
                        ? (evt) => {
                            onCancelClick(evt);
                            if (typeof button.onPress === 'function') {
                              button.onPress(evt);
                            }
                          }
                        : (event) => button.onPress(event, onCancel)
                    }>
                    {button.text}
                  </Button>
                ))}
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const useStyles = makeStyles((theme: AppThemeType) =>
  StyleSheet.create({
    overlay: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#0009',
    },
    modal: {
      backgroundColor: '#fff',
      padding: 18,
      maxWidth: '80%',
    },
    heading: {
      fontFamily: theme.typography.fonts.medium,
      fontSize: rem(1.125),
      lineHeight: 26,
      marginBottom: 18,
    },
    message: {
      fontFamily: theme.typography.fonts.regular,
      fontSize: rem(1),
      lineHeight: 18,
    },
    actions: {
      flex: 1,
      marginTop: 18,
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
  }),
);

AlertDialog.propTypes = {
  visible: PropTypes.bool,
  options: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
          .isRequired,
        onPress: PropTypes.func,
        style: PropTypes.oneOf(['OK', 'cancel']),
      }),
    ),
  }),
};

export default AlertDialog;
