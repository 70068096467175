export const SHOW_ACTIVITIES_LIST_TAB_BAR = 'SHOW_ACTIVITIES_LIST_TAB_BAR';

export const showActivitiesListTabBarReducer = () => ({
  activitiesListTabBarVisible: true,
});

export const HIDE_ACTIVITIES_LIST_TAB_BAR = 'HIDE_ACTIVITIES_LIST_TAB_BAR';

export const hideActivitiesListTabBarReducer = () => ({
  activitiesListTabBarVisible: false,
});
