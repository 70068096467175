export const SET_WORKFLOWS = 'SET_WORKFLOWS';

export const setWorkflowsReducer = (
  global,
  dispatch,
  workflows: { data?: any[], loading?: boolean, init?: boolean, error?: any },
) => ({
  workflows: {
    ...global.workflows,
    ...workflows,
  },
});

export const SET_WORKFLOW_MODULES = 'SET_WORKFLOW_MODULES';

export const setWorkflowModulesReducer = (
  global,
  dispatch,
  modules: { data?: any[], loading?: boolean, init?: boolean, error?: any },
) => ({
  modules: {
    ...global.modules,
    ...modules,
  },
});

export const SET_WORKFLOW_STATES = 'SET_WORKFLOW_STATES';

export const setWorkflowStatesReducer = (
  global,
  dispatch,
  states: { data?: any[], loading?: boolean, init?: boolean, error?: any },
) => ({
  states: {
    ...global.states,
    ...states,
  },
});
